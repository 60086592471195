<template>
    <Layout>
        <h4>Reference Number : {{ details.ref_no }}</h4>
        <b-overlay :show="show" rounded="sm">
            <div class="row">
                <div class="col-lg-5">
                    <b-tabs
                        active-nav-item-class="font-weight-bold text-uppercase text-primary"
                        active-tab-class="font-weight-bold text-success"
                        content-class="mt-3"
                    >
                        <b-tab title="Request Details" active>
                            <div>
                                <p>
                                    <span class="title">
                                        Number of remotes :</span
                                    >
                                    &nbsp;&nbsp;{{ details.no_of_remotes }}
                                </p>

                                <p>
                                    <span class="title"> Created At :</span>
                                    &nbsp;&nbsp;{{ details.created_at }}
                                </p>
                                <div
                                    v-for="status in requestStatus"
                                    :key="status.id"
                                >
                                    <p
                                        v-if="status.id === details.status"
                                        :class="status.text"
                                    >
                                        <span class="title"> Status :</span>
                                        &nbsp;&nbsp;
                                        {{ status.text }}
                                    </p>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Contact Information">
                            <div>
                                <p>
                                    <span class="title"> Name :</span>
                                    &nbsp;&nbsp;{{ details.user_name }}
                                </p>
                                <p>
                                    <span class="title"> Email :</span>
                                    &nbsp;&nbsp;{{ details.email }}
                                </p>
                                <p>
                                    <span class="title"> Nationality :</span>
                                    &nbsp;&nbsp;{{ details.nationality }}
                                </p>
                                <p>
                                    <span class="title"> PO Box Number :</span>
                                    &nbsp;&nbsp;{{ details.po_box }}
                                </p>
                                <p>
                                    <span class="title">
                                        Passport Number :</span
                                    >
                                    &nbsp;&nbsp;{{ details.passport_no }}
                                </p>
                                <p>
                                    <span class="title"> Mobile :</span>
                                    &nbsp;&nbsp;{{ details.mobile }}
                                </p>
                            </div>
                        </b-tab>

                        <b-tab title="Documents">
                            <div class="documents">
                                <p>
                                    Passport&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="viewDocument(details.passport)"
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>
                                <p>
                                    Emirates Id&nbsp;&nbsp;:&nbsp;&nbsp;

                                    <b-button
                                        @click="viewDocument(details.eid)"
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>
                                <p>
                                    Visa Copy&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="viewDocument(details.visa)"
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                <div class="col-lg-7">
                    <addComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />
                    <addRejectComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />
                    <workFlowDiagram
                        :workAssigned="workAssigned"
                        :details="details"
                    />
                </div>
            </div>

            <br />
            <accountsApproval :details="details" :requestType="requestType" />

            <br />
            <updateRequestStatus
                :details="details"
                :requestType="requestType"
            />
            <br />
        </b-overlay>
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */

import Layout from "../../layouts/main.vue";
import { BASE_URL, REQUEST_STATUS } from "../../common";
import axios from "axios";
import unitMixin from "../../../mixins/unitMixin";
import addComment from "../requestFlow/addComments.vue";
import addRejectComment from "../requestFlow/addRejectionComments.vue";
import workFlowDiagram from "../requestFlow/workFlow.vue";
import updateRequestStatus from "../requestFlow/statusUpdate.vue";
import accountsApproval from "../requestFlow/accountsApproval.vue";
export default {
    mixins: [unitMixin],
    components: {
        Layout,
        addComment,
        addRejectComment,
        workFlowDiagram,
        updateRequestStatus,
        accountsApproval,
    },
    mounted() {
        console.log(this.$route.params.id);
        this.getDetails(this.$route.params.id);
    },
    data() {
        return {
            requestStatus: REQUEST_STATUS,
            test: "admin",
            show: true,
            details: {},
            requestType: null,
            workAssigned: [],
        };
    },
    methods: {
        refetchItem() {
            this.getDetails(this.$route.params.id);
        },
        getDetails(id) {
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}barrier-remote-controls/${id}/?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        console.log(res.data.response);
                        this.show = false;
                        this.details = res.data.response;
                        this.requestType = res.data.request_type;
                        this.workAssigned = res.data.workassigned;
                    });
            }
        },
    },
};
</script>
<style scoped>
.documents {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.title {
    font-weight: bolder;
}
p {
    color: black;
}

.Pending {
    color: #ff635c;
}
.Started {
    color: #ffc71a;
}
.Completed {
    color: #19b699;
}
.Closed {
    color: #777;
}
#awaiting {
    color: #1b3863;
}
</style>
